import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { createComment, getAllComments } from './../../redux/slices/comment/commentThunk';
import { useDispatch, useSelector } from './../../redux/store';
import { stringAvatar } from './../../utils/stringAvatar';
import { useAuthContext } from './../../auth/useAuthContext';
import { useSnackbar } from 'notistack';
function ChatOrder({ setDescriptionState, descriptionState, commandId }) {
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { comments, isLoadingCreateComment } = useSelector((state) => state.comment);
    useEffect(() => {
        dispatch(getAllComments({ commandId }));
    }, [commandId]);
    const handleSendMessage = () => {
        if (descriptionState === '') {
            return;
        }
        dispatch(createComment({
            params: {
                commandId: commandId,
                commentData: {
                    content: descriptionState,
                },
            },
        })).then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
                enqueueSnackbar('message envoyee avec succès', { variant: 'success' });
                dispatch(getAllComments({ commandId }));
                setDescriptionState('');
            }
            else {
                enqueueSnackbar(res?.error?.message, { variant: 'error' });
            }
        });
    };
    return (_jsxs(Stack, { sx: {
            width: '100%',
        }, gap: 2.5, children: [comments?.map((comment) => {
                const isMeTheSender = user?.roles?.[0].code === comment?.createdBy?.roles?.[0]?.code;
                return (_jsxs(Stack, { flexDirection: isMeTheSender ? 'row-reverse' : 'row', gap: 5, children: [_jsxs(Stack, { alignItems: "center", gap: "10px", children: [_jsx(Avatar, { src: 'data?.avatar', ...stringAvatar(`${comment?.createdBy?.fullName}`), sx: { width: 50, height: 50, background: '#DAF6FF' } }), _jsx(Typography, { sx: {
                                        color: '#6D758F',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        textTransform: 'capitalize',
                                    }, children: comment?.createdBy?.fullName })] }), _jsx(Box, { sx: { borderRadius: 2, background: '#F8FAFF', flex: 1, p: 2 }, children: _jsx(Typography, { variant: "body2", color: "#788393", children: comment?.content }) })] }, comment.id));
            }), _jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold' }, children: "Laisser commentaire:" }), _jsx(TextField, { sx: { marginTop: '10px' }, fullWidth: true, multiline: true, rows: 4, value: descriptionState, onChange: (e) => {
                            setDescriptionState(e.target.value);
                        }, label: 'Entrer Votre commentaire ici' }), _jsx(Stack, { flexDirection: "row", justifyContent: "flex-end", children: _jsx(LoadingButton, { onClick: handleSendMessage, loading: isLoadingCreateComment, loadingIndicator: _jsx(CircularProgress, { style: { color: 'white' }, size: 24 }), sx: {
                                background: 'linear-gradient(271deg, #2788E7 0%, #5EC4E0 107.92%), #FFF',
                                color: 'white',
                                my: 2,
                                borderRadius: 1,
                                padding: 1.3,
                                marginBottom: '3rem',
                                width: 200,
                            }, children: "Envoyer" }) })] })] }));
}
export default ChatOrder;
